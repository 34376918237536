import { BASE_PASSWORD, BASE_USERNAME } from '@pumpkincare/portal-config';

export function responseDataBody(response) {
  return response?.data?.body || response?.data;
}

export function buildAuthHeaders(authSession) {
  return {
    headers: {
      Authorization: `Bearer ${authSession.tokens.idToken.toString()}`,
    },

    response: true,
  };
}

export function buildVetAuthHeaders() {
  const credentials = `${BASE_USERNAME}:${BASE_PASSWORD}`;
  return {
    headers: {
      Authorization: `Basic ${btoa(credentials)}`,
    },
    response: true,
  };
}
